import {CartFunctions} from '../Common';
import {Symfony} from '../modules/Symfony';
import 'jquery-typeahead';

class Home {
    private postUrl: string;

    constructor() {
        this.postUrl = $('#homePosts').data('url');
        this.initEventHandlers();
        this.initSliders();
        this.initSidebar();
        this.initTooltipster();
        this.initAutocomplet();
    }

    private initEventHandlers() {
        const self = this;
        $(document).on('click', '.jsToCart', function (e: Event) {
            e.preventDefault();
            e.stopPropagation();
            CartFunctions.addToCart($(this), 1);
        });

        $('.jsQuickStartConfigurator').on('change', 'select,input', function () {
            self.updateQuickStartConfigurator($(this));
        });
        $('.jsContinue').on('click', function (e) {
            e.preventDefault();
            self.continueConfiguration($(this));
        });
        $('.jsSliderImage').on('click', function (e: Event) {
            $.post(self.postUrl, {
                action: 'getSliderLink',
                sliderId: $(this).data('id'),
            }, (response) => {
                if (response.status === 'ok') window.location = response.url;
            });
        });

        $(document).on('click', '.jsBundle', function (e: Event) {
            e.preventDefault();
            const id = $(this).data('id');
            $.post(self.postUrl, {
                action: 'putBundleToCart',
                bundleID: id,
            }).then((response) => {
                if (response.status === 'ok') window.location = response.url;
            });
        });

        $(document).on('submit', '#quickEntryForm', function (e: Event) {
            e.preventDefault();
            Symfony.postForm(this, (response) => {
                if (response.redirect) {
                    window.open(response.redirect, '_self');
                } else {
                    $('#jsQuickEntryForm').html(response.form);
                    self.initAutocomplet();
                }
            });
        });

        $('.accordion h3').on('click', function (e) {
            if ($(window).width() < 900) {
                $(this).next('p').slideToggle();
            }
        });

        $('.jsToggleConfiguratorSidebar').on('click', (e) => {
            $('#configuratorQuickstartMenu').trigger('click');
        });
    }

    private initSliders() {
        $('.jsSlideshow').slick({
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: false,
            dots: true,
            pauseOnHover: false,
            speed: 800,
            centerMode: true,
            variableWidth: true,
            lazyLoad: 'ondemand',
        });

        $('.jsHighlightSlideshow').slick({
            slidesToShow: 5,
            prevArrow: '<i class="far fa-chevron-left arrow"></i>',
            nextArrow: '<i class="far fa-chevron-right arrow"></i>',
            lazyLoad: 'ondemand',
            rows: 0, // Disable grid mode
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });

        $('.jsDealsSlideshow').slick({
            slidesToShow: 5,
            prevArrow: '<i class="far fa-chevron-left arrow"></i>',
            nextArrow: '<i class="far fa-chevron-right arrow"></i>',
            rows: 0, // Disable grid mode
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
        });
    }

    private initSidebar() {
        // @ts-ignore
        // $('#configuratorSidebar').simplerSidebar({
        //     toggler: '#configuratorMenu',
        //     top: 48,
        //     align: 'left',
        // });
    }

    private initTooltipster() {
        if ($('#configuratorQuickstartMenu:visible').length > 0) {
            setTimeout(() => {
                import('tooltipster/dist/js/tooltipster.bundle.min').then(() => {
                    $('#configuratorQuickstartMenu').tooltipster({
                        content: $.trans('Open quick start configurator here.'),
                        side: 'bottom',
                        theme: 'tooltipster-shadow',
                        timer: 5000,
                        autoClose: true,
                        trigger: 'click',
                        triggerClose: {
                            originClick: true,
                            tap: true,
                        },
                    }).tooltipster('open');
                });
            }, 1000);
        }
    }

    private getRackSettings($quickStart) {
        const data = {};
        const rackCategory = $quickStart.find('.jsRackCategory');
        data[rackCategory.attr('name')] = rackCategory.val();
        const shelfLoad = $quickStart.find('.jsShelfLoad');
        data[shelfLoad.attr('name')] = shelfLoad.val();
        const rackHeight = $quickStart.find('.jsRackHeight');
        data[rackHeight.attr('name')] = rackHeight.val();
        const rackDepth = $quickStart.find('.jsRackDepth');
        data[rackDepth.attr('name')] = rackDepth.val();
        const fieldWidth = $quickStart.find('.jsFieldWidth');
        data[fieldWidth.attr('name')] = fieldWidth.val();
        return data;
    }

    private continueConfiguration(button) {
        const $quickStart = button.parents('.jsQuickStartConfigurator');
        const data = this.getRackSettings($quickStart);
        $.post('/prepareConfiguration', data, (response) => {
            $.response(response, (response) => {
                document.location.assign(response.location);
            });
        });
    }

    private updateQuickStartConfigurator(trigger) {
        const self = this;
        const $quickStart = trigger.parents('.jsQuickStartConfigurator');
        const data = this.getRackSettings($quickStart) as any;
        data.trigger = trigger.attr('name');
        $.post('/updateQuickStartConfigurator', data, (res) => {
            $.response(res, (response: Record<string, any>) => {
                if (response.shelfLoadTitle) {
                    $quickStart.find('.jsShelfLoad').siblings('span').text(response.shelfLoadTitle);
                }
                if (response['shelf-load']) {
                    self.updateSelect('shelf-load', response);
                }
                if (response['arm-load']) {
                    self.updateArmLoad(response);
                }
                if (response.width) {
                    self.updateSelect('width', response);
                }
                if (response.depth) {
                    self.updateSelect('depth', response);
                }
                if (response.height) {
                    self.updateSelect('height', response);
                }
            });
        });
    }

    updateArmLoad(response: any) {
        $('.jsShelfLoad').replaceWith('<input class="jsShelfLoad" name="shelf-load" type="number" min="10" max="3900" step="5" value="10">');
    }

    private updateSelect(name, data) {
        let $select = $('select[name="' + name + '"');
        if ($select.length == 0 && name == 'shelf-load') {
            $select = $('.jsShelfLoad').replaceWith('<select name="shelf-load" class="jsShelfLoad"></select>');
            $select = $('.jsShelfLoad');
            let first = true;
            for (const option of data[name]) {
                $select.append($('<option value="' + option.id + '" data-value="' + option.value + '"' + (first ? ' selected' : '') + '>' + option.label + '</option>'));
                first = false;
            }
        } else {
            const before = $select.val();
            $select.empty();

            for (const option of data[name]) {
                $select.append($('<option value="' + option.id + '" data-value="' + option.value + '"' + (before === option.id ? ' selected' : '') + '>' + option.label + '</option>'));
            }
        }
    }

    private initAutocomplet() {
        const self: Home = this;
        $.typeahead({
            input: '#quick_entry_productItem',
            minLength: 1,
            maxItem: 20,
            order: 'asc',
            dynamic: true,
            delay: 500,
            backdrop: {'background-color': '#eee', 'z-index': 100},
            template: "<small style='color:#999;'>{{id}} {{title}}</small>",
            emptyTemplate: $.trans('No results for typed string'),
            source: {
                productItem: {
                    display: ['id', 'title'],
                    ajax(query) {
                        return {
                            type: 'POST',
                            url: self.postUrl,
                            path: 'productItem',
                            data: {
                                search: '{{query}}',
                                length: '40',
                                action: 'autocomplete',
                            },
                            callback: {
                                done(res) {
                                    const d = {
                                        productItem: undefined,
                                    };
                                    d.productItem = [];
                                    $(res.data).each((index, value) => {
                                        d.productItem.push(value.productItem);
                                    });
                                    return d;
                                },
                            },
                        };
                    },
                },
            },
            callback: {
                onClickAfter(node, a, item, event) {
                    event.preventDefault();
                    $(node).val('[' + item.id + '] ' + item.title);
                },
            },
            debug: false,
        });
    }
}

$(() => {
    new Home();
});
