export class CartFunctions {
    public static addToCart($element, amount = null, removeOffer = false) {
        let quantity = amount;
        if (amount === null) {
            quantity = $element.parents('form').find('#to_cart_form_amount').val();
        }
        $.post($element.data('url'), {
            action: 'setData',
            productItem: $element.data('product-item'),
            _token: $element.data('csrf'),
            ajax: true,
            amount: quantity,
            removeOffer,
        }, (response) => {
            if (response.status === 'ok') {
                $('.jsToCartOverlay').show().html(response.content);

                const $badge = $('.jsCartBadge');
                if ($badge.length > 0) {
                    const responseText = $(response.badge).text();
                    $badge.text(responseText);
                } else {
                    $('.jsCartIcon').after($(response.badge));
                }
            } else if (response.reason === 'offer') {
                $.popup({
                    headline: $.trans('Expiry of the offer'),
                    text: $.trans('By making changes to your shopping cart, your offer expires. Do you still want to proceed?'),
                    customButtons: [
                        {
                            title: $.trans('Proceed'),
                            buttonFunction: () => {
                                CartFunctions.addToCart($element, amount, true);
                                return true;
                            },
                        }, {
                            title: $.trans('Abort'),
                            buttonFunction: () => true,
                        },
                    ],
                    width: 400,
                });
            }
        });
    }
}

export class WishlistFunctions {
    public static addToWishlist($element) {
        if (!$element.hasClass('jsOnWishlist')) {
            const url = $element.data('url');
            $.post(url, {
                amount: 1,
            }).then((response) => {
                $.response(response, (responseData) => {
                    import('tooltipster/dist/js/tooltipster.bundle.min').then(() => {
                        $('.jsWishlistTooltip').tooltipster({
                            content: responseData.message,
                            side: 'bottom',
                            theme: 'tooltipster-shadow',
                            trigger: 'custom',
                            timer: 5000,
                        }).tooltipster('open');
                    });
                    const $badge = $('.jsWishlistBadge');
                    if ($badge.length > 0) {
                        const responseText = $(responseData.badge).text();
                        $badge.text(responseText);
                    } else {
                        $('.jsWishlistIcon').after($(responseData.badge));
                    }
                    $element.addClass('jsOnWishlist');
                    $element.children('.fa-star').removeClass('fa-star').addClass('fa-check');
                });
            });
        }
    }
}
