export class Symfony {
    public static postForm(form: Object, callback: (response: any) => void, postData: Object = {}): void {
        const formURL = $(form).prop('action');
        const formName = $(form).prop('name');
        postData[formName] = Symfony.serializeForm(form);
        // @ts-ignore
        postData.ajax = true;

        $.post(formURL, postData, (response) => {
            $.response(response, (response) => {
                if (callback) {
                    callback(response);
                }
            });
        });
    }

    public static postUploadForm(form: any, callback: (response: any) => void, postData: Object = {}): void {
        const $form = $(form);
        const formURL = $form.prop('action');
        const formData = new FormData($form[0]);
        Object.keys(postData).forEach((key) => formData.append(key, postData[key]));
        formData.append('ajax', 'true');
        $.ajax({
            url: formURL,
            data: formData,
            type: 'post',
            contentType: false,
            processData: false,
            cache: false,
            error(err) {
                console.error(err);
            },
            success(response) {
                if (callback) {
                    callback(response);
                }
            },
            complete() {
                // console.log("Request finished.");

            },
        });
    }

    public static serializeForm(form) {
        const data = {};
        const formData = $(form).serializeArray();
        formData.forEach((object) => {
            const key = object.name.match(/\[(.*)]/i);
            if (data.hasOwnProperty(key[1])) {
                if (!Array.isArray(data[key[1]])) {
                    const value = data[key[1]];
                    data[key[1]] = [];
                    data[key[1]].push(value);
                }
                data[key[1]].push(object.value);
            } else data[key[1]] = object.value;
        });

        return data;
    }

    public static exportCSVFile(csv, name) {
        const exportedFilename = name;
        const blob = new Blob([csv], {type: 'text/csv;charset=utf-8;'});

        // @ts-ignore
        if (navigator.msSaveBlob) { // IE 10+
            // @ts-ignore
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
}

export class Cookie {
    public static setCookie(name: string, value: any, days ?: number) {
        let expires: string;

        if (days) {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = '; expires=' + date.toUTCString();
        } else {
            expires = '';
        }
        document.cookie = encodeURIComponent(name) + '=' + encodeURIComponent(value) + expires + '; path=/';
    }

    public static getCookie(name: string): string | null {
        const nameEQ = encodeURIComponent(name) + '=';
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return decodeURIComponent(c.substring(nameEQ.length, c.length));
        }
        return null;
    }

    public static hasCookie(name: string): boolean {
        return this.getCookie(name) !== null;
    }

    public static removeCookie(name: string): void {
        this.setCookie(name, '', -1);
    }
}
